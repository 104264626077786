/*! =========================================================
 *
 * Material Kit React v1.3.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 *
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-pro
 * Available with purchase of license from https://www.creative-tim.com/product/material-kit-pro
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * License Creative Tim (https://www.creative-tim.com/license)
 *
 * ========================================================= */

@import "core/variables";
@import "core/mixins";

// Core Components
@import "core/misc";

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-slick";
